import React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";

import { creator, types } from "../store";

import Content from "../components/templates/Content";
import SmallBox from "../components/molecules/SmallBox";
import InfoBoxList, { ListItem } from "../components/molecules/InfoBoxList";
import CurrentMonthPaymentAmountSection from "../components/molecules/CurrentMonthPaymentAmount";
import LmndModal from "../components/templates/LmndModal";
import { EXTERNAL_SERVICE_NAMES, EXTERNAL_SERVICE_LINKS } from "../constants";
import { icons } from "../utils/icons";
import Alert from "../components/molecules/Alert";
import { useEffect, useState } from "react";

const Home: React.FC = (): JSX.Element => {
  // 丁寧にrenderingするのなら、nameがnullの時にはrenderingしない処理を入れても良いかも
  const [homeAlert, setHomeAlert] = useState<string | null>("");
  const creatorState: creator.types.State = useSelector<
    types.RootState,
    creator.types.State
  >((state) => state.creator);
  const isAvailableRevenue = creatorState.isRevenueViewed;
  const questionItems: ListItem[] = [
    { text: "Q.UUUMマイページとは？" },
    { text: "Q.月別の収益情報を確認するには？" },
    { text: "Q.支払い証明書をダウンロードするには？" },
  ];

  const serviceItems: ListItem[] = creatorState.availableServices?.map(
    (service: creator.types.CreatorAvailableServices): ListItem => ({
      text: EXTERNAL_SERVICE_NAMES[service],
      icon: icons[service],
      to: EXTERNAL_SERVICE_LINKS[service],
    })
  );

  const [showModal, setShowModal] = useState(false);
  const [shouldShowModal, setShouldShowModal] = useState(false);

  useEffect(() => {
    if (sessionStorage.getItem("ShowLmndModal")) {
      setShouldShowModal(true);
      sessionStorage.removeItem("ShowLmndModal");
    }
  }, []);

  const handleCloseModal = () => setShowModal(false);

  useEffect(() => {
    if (shouldShowModal) {
      setShowModal(true);
    }
  }, [shouldShowModal]);

  useEffect(() => {
    setHomeAlert(sessionStorage.getItem("HomeAlert"));
    sessionStorage.removeItem("HomeAlert");
  }, []);

  return (
    <Content>
      {homeAlert && <Alert title={homeAlert} type="info" />}
      <StyledRow>
        <StyledCol>
          <SmallBox title={`ようこそ、\n${creatorState.name}さん`}>
            <p>
              UUUMマイページは、収益確認・個人情報・支払い口座設定を管理できます。
            </p>
          </SmallBox>
          {isAvailableRevenue && (
            <SmallBox title="収益" link="/revenues">
              <CurrentMonthPaymentAmountSection />
            </SmallBox>
          )}
        </StyledCol>
        <StyledCol>
          {creatorState.availableServices?.length > 0 && (
            <SmallBox title="利用サービス">
              <InfoBoxList items={serviceItems} additionalMarginBottom={0.8} />
            </SmallBox>
          )}
          <SmallBox title="よくある質問" link="/question">
            <InfoBoxList items={questionItems} />
          </SmallBox>
        </StyledCol>
      </StyledRow>
      <LmndModal open={showModal} onClose={handleCloseModal}></LmndModal>
    </Content>
  );
};

const StyledRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-right: -1.2rem;
  margin-left: -1.2rem;

  @media (max-width: 896px) {
    margin: 0;
  }
`;

const StyledCol = styled.div`
  flex: 0 50%;
  position: relative;
  width: 100%;
  max-width: 50%;
  padding-right: 1.2rem;
  padding-left: 1.2rem;

  @media (max-width: 896px) {
    flex: 0 100%;
    max-width: 100%;
    padding: 0;
  }
`;

export default Home;
